<template>
  <div>
    <!-- User Interface controls -->
    <b-card>
      <b-card-body>
        <div>
          <b-row>
            <b-col :md="colService">
              <!-- ====Liste des services=== -->
              <b-row>
                <b-col>
                  <h4>Liste des services</h4>
                </b-col>
                <b-col class="text-right">
                  <div>
                    <b-tooltip target="add-service-tooltip" triggers="hover">
                      Ajouter un Service
                    </b-tooltip>
                    <button
                      id="add-service-tooltip"
                      v-b-modal.add-service-modal
                      serviceId="row.item.id"
                      class="btn btn-sm mb-2 mybtn"
                    >
                      <feather-icon icon="PlusCircleIcon" size="20" />
                    </button>
                  </div>
                </b-col>
              </b-row>
              <b-table
                responsive
                hover
                :items="services"
                :empty-text="'Aucun service disponible'"
                :fields="serviceItems"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filter-included-fields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                show-empty
                @row-clicked="fetchServiceFonctions"
              >
                <template #cell(actions)="row">
                  <b-row class="text-right">
                    <b-col class="col-md-3">
                      <button
                        id="add-service-tooltip"
                        v-b-modal.add-service-modal
                        class="btn btn-sm mybtn"
                        @click="showThisService(row.item)"
                      >
                        <feather-icon icon="EditIcon" size="20" />
                      </button>
                    </b-col>

                    <!-- <b-col class="col-md-2">
                      <button class="btn btn-sm">
                        <feather-icon icon="TrashIcon" size="20" />
                      </button>
                    </b-col> -->
                  </b-row>
                </template>
              </b-table>
            </b-col>

            <b-col :md="colFonction" v-show="showFonctionTable">
              <b-row>
                <b-col md="auto">
                  <h4>Fonctions : {{ service.name }}</h4>
                </b-col>
                <b-col class="text-right">
                  <div>
                    <b-tooltip target="add-fonction-tooltip" triggers="hover">
                      Ajouter une fonction
                    </b-tooltip>
                    <button
                      id="add-fonction-tooltip"
                      v-b-modal.add-fonction-modal
                      class="btn btn-sm mb-2 mybtn"
                      title="Ajouter une fonction"
                    >
                      <feather-icon icon="PlusCircleIcon" size="20" />
                    </button>
                  </div>
                </b-col>
              </b-row>
              <b-table
                fixed
                :items="fonctions"
                :empty-text="'Aucune fonction disponible'"
                :fields="fonctionItems"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filter-included-fields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                show-empty
              >
                <template #cell(actions)="row">
                  <b-row class="text-right">
                    <b-col class="col-md-3">
                      <button
                        id="add-fonction-tooltip"
                        v-b-modal.add-fonction-modal
                        class="btn btn-sm mybtn"
                        @click="showThisFonction(row.item)"
                      >
                        <feather-icon icon="EditIcon" size="20" />
                      </button>
                    </b-col>

                    <!-- <b-col class="col-md-2">
                      <button class="btn btn-sm">
                        <feather-icon icon="TrashIcon" size="20" />
                      </button>
                    </b-col> -->
                  </b-row>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </div>

        <!-- ADD SERVICE MODAL -->
        <b-modal
          ref="my-modal"
          show="resetModal"
          id="add-service-modal"
          centered
          modal-class="modal-primary"
          title="Ajouter un service"
          hide-footer
          hide-header-close
          no-close-on-backdrop
        >
          <validation-observer ref="serviceForm" #default="{ invalid }">
            <b-form>
              <b-form-group>
                <validation-provider :rules="'required'">
                  <b-form-input
                    placeholder="Nom du service"
                    v-model="service.name"
                    type="text"
                  />
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <b-form-textarea
                  v-model="service.description"
                  placeholder="Description du service"
                  rows="4"
                />
              </b-form-group>
              <div v-if="errors" class="alert alert-danger p-1">
                <span>{{ errors.message }}</span
                ><br />
                <span> - {{ errors.errors.name[0] }}</span>
              </div>
              <b-form-group>
                <b-button
                  class="mr-1"
                  v-if="wantToUpdate == false"
                  @click.prevent="addServices"
                  :disabled="invalid"
                  >Ajouter</b-button
                >
                <b-button
                  class="mr-1"
                  v-if="wantToUpdate == true"
                  @click.prevent="editService"
                  >Modifier</b-button
                >
                <button
                  @click.prevent="hideServiceModal"
                  class="btn btn-outline-secondary"
                >
                  Annuler
                </button>
              </b-form-group>
            </b-form>
          </validation-observer>
        </b-modal>

        <!-- ADD FONCTION MODAL -->
        <b-modal
          ref="my-modal"
          show="resetModal"
          id="add-fonction-modal"
          centered
          modal-class="modal-primary"
          title="Ajouter une fonction"
          hide-footer
          no-close-on-backdrop
          hide-header-close
        >
          <validation-observer ref="fonctionForm" #default="{ invalid }">
            <b-form>
              <b-form-group>
                <validation-provider :rules="'required'">
                  <b-form-input
                    placeholder="Nom du fonction"
                    v-model="fonction.name"
                    type="text"
                  />
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <b-form-textarea
                  v-model="fonction.description"
                  placeholder="Description de la fonction"
                  rows="4"
                />
              </b-form-group>
              <div v-if="errorMessage" class="alert alert-danger">
                <span> {{ errorMessage }}</span>
              </div>
              <b-form-group>
                <b-button
                  class="mr-1"
                  v-if="wantToUpdate == false"
                  @click.prevent="addFonctions"
                  :disabled="invalid"
                  >Ajouter</b-button
                >
                <b-button
                  class="mr-1"
                  v-if="wantToUpdate == true"
                  @click.prevent="editFonction"
                  >Modifier</b-button
                >
                <button
                  @click.prevent="hideFonctionModal"
                  class="btn btn-outline-secondary"
                >
                  Annuler
                </button>
              </b-form-group>
            </b-form>
          </validation-observer>
        </b-modal>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { BCard, BCardBody } from "bootstrap-vue";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    BCard,
    BCardBody,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      service: {},
      fonction: {},
      serviceItems: [
        {
          key: "name",
          label: "Nom",
          sortable: true,
          thStyle: { width: "20%" },
        },
        {
          key: "description",
          label: "Description",
          sortable: true,
          thStyle: { width: "60%" },
        },
        {
          key: "actions",
          label: "",
          sortable: true,
          thStyle: { width: "20%" },
        },
      ],
      fonctionItems: [
        { key: "name", label: "Nom" },
        //{ key: "description", label: "Description" },
        { key: "actions", label: "" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      // pageOptions: [5, 10, 15, { value: 100, text: "Tout afficher" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],

      error: "",
      errors: "",
      errorMessage: "",
      errorFonction: "",
      showFonctionTable: false,
      wantToUpdate: false,
      colService: "",
      colFonction: "",
    };
  },
  computed: {
    ...mapState("enterprise", ["services", "fonctions"]),
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.services.length;
    this.fetchServices();
    this.serviceId;
  },
  methods: {
    showThisService(data) {
      this.wantToUpdate = true;
      this.showService(data.id).then((e) => {
        this.service = data;
      });
    },

    showThisFonction(data) {
      this.wantToUpdate = true;
      this.showFonction(data.id).then((e) => {
        this.fonction = data;
      });
    },

    hideFonctionModal() {
      this.wantToUpdate = false;
      this.fonction = {};
      this.$refs["my-modal"].hide();
    },
    hideServiceModal() {
      this.wantToUpdate = false;
      this.service = {};
      this.$refs["my-modal"].hide();
    },
    fetchServiceFonctions(data) {
      this.fetchFonctions(data.id).then((e) => {
        this.service = data;
        this.colService = 8;
        this.colFonction = 4;
        this.showFonctionTable = true;
      });
    },

    detailService(data) {
      this.service = data;
    },
    async addServices() {
      const valid = await this.$refs.serviceForm.validate();
      if (!valid) return;
      this.loading = true;
      this.addService(this.$formData(this.service))
        .then((r) => {
          this.loading = true;
          this.service.name = "";
          this.service.description = "";
          this.resetModal = false;
          this.$refs["my-modal"].hide();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Service ajouté avec succès",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.errorMessage = error.message;
          this.errors = error.response.data;
        });
    },
    async editService() {
      const valid = await this.$refs.serviceForm.validate();
      if (!valid) return;
      this.loading = true;
      this.updateService(this.$formData(this.service))
        .then((r) => {
          this.loading = true;
          this.service = {};
          this.wantToUpdate = false;
          this.resetModal = false;
          this.$refs["my-modal"].hide();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Service modifié avec succès",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.errorMessage = error.message;
        });
    },
    async addFonctions() {
      const valid = await this.$refs.fonctionForm.validate();
      if (!valid) return;
      this.loading = true;
      this.fonction.service_id = this.service.id;
      this.addFonction(this.$formData(this.fonction))
        .then((r) => {
          this.loading = true;
          this.fonction.name = "";
          this.fonction.description = "";
          this.resetModal = false;
          this.$refs["my-modal"].hide();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Fonction ajoutée avec succès",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.errorMessage = error.message;
          this.popover_disabled = false;
          this.$refs.form_popover.$emit("open");
        });
    },

    async editFonction() {
      const valid = await this.$refs.fonctionForm.validate();
      if (!valid) return;
      this.loading = true;
      this.updateFonction(this.$formData(this.fonction))
        .then((r) => {
          this.loading = true;
          this.fonction = {};

          this.wantToUpdate = false;
          this.resetModal = false;
          this.$refs["my-modal"].hide();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Fonction modifié avec succès",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.errorMessage = error.message;
        });
    },

    ...mapActions("enterprise", [
      "addService",
      "fetchServices",
      "showService",
      "showFonction",
      "fetchFonctions",
      "addFonction",
      "updateService",
      "updateFonction",
    ]),
  },

  watch: {},
};
</script>

<style>
.mybtn:hover {
  border-bottom: 1px solid;
  size: 10px;
}
</style>
